import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
  image5: File;
  image6: File;
  image7: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(
      relativePath: { eq: "projekte/reset_the_forest/image1.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(
      relativePath: { eq: "projekte/reset_the_forest/image2.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(
      relativePath: { eq: "projekte/reset_the_forest/image3.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(
      relativePath: { eq: "projekte/reset_the_forest/image4.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image5: file(
      relativePath: { eq: "projekte/reset_the_forest/image5.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image6: file(
      relativePath: { eq: "projekte/reset_the_forest/image6.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image7: file(
      relativePath: { eq: "projekte/reset_the_forest/image7.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="Reset the forest"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://www.youtube.com/embed/96vy1lfN5DI"
            width="100%"
          />
          <div className="flex-1 py-32 mb-16">
            <h3>
              Künstlerkollektiv - Katharina Berndt, Tom Groll und Kuno Seltmann
            </h3>
            <p>
              Ein kleines Stück Rinde zeigt die Muster, die der Borkenkäfer in
              den Baum bohrt, in natura. Überdimensional, fast kathedralartig,
              findet sich dieses Muster an den großen Glasfenstern zum
              Museumsgarten als filigrane Scherenschnittarbeit wieder. Das
              Filigrane bildet ein Gegengewicht zu den wuchtigen, tonnenschweren
              Altpapierballen, die skulptural in den Raum greifen. Ergänzend
              dazu führt ein experimenteller Dokumentarfilm aussagekräftig vor
              Augen, wie es um den Wald bestellt ist und wo die eigentliche
              Ursache des Waldsterbens liegt.
            </p>
            <div className="flex flex-col my-32">
              <iframe
                title="Interview zu Reset the forest"
                className="h-256 lg:h-512"
                frameBorder="0"
                allow="fullscreen; display-capture; autoplay"
                src="https://www.youtube.com/embed/RpxgMfJmQUc"
                width="100%"
              />
            </div>
            <p className="pb-16">
              Scherenschnitt, Altpapierballen und Dokumentarfilm gehören
              zusammen, sind Teil der raumgreifenden Installation{' '}
              <b>„Reset the Forest“</b>, mit der das{' '}
              <b>
                Künstlerkollektiv Katharina Berndt, Tom Groll und Kuno Seltmann
              </b>{' '}
              im Rahmen der „WWW WorldWideWunderkammer“ künstlerisch auf den
              Klimawandel und das Waldsterben aufmerksam macht. Der Mensch ist
              der Verursacher der Katastrophe. Ein Umdenken ist dringend
              vonnöten. Analog und digital rüttelt die dreiteilige Arbeit, zu
              der hintergründige Texte über den Borkenkäfer und den Harvester
              als Waldvollerntemaschine samt der durch sie verursachten Schäden
              gehören, auf.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 md:-mb-80 lg:-mb-160 self-end z-10 w-full md:w-1/3"
                objectFit="cover"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Projekt Reset the Forest"
              />
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Projekt Reset the Forest"
              />
            </div>
            <p>
              Wer der Schädling ist, der Mensch oder der Käfer, drängt sich als
              Frage auf. Auch die von Guido Meyer eingesprochenen Texte, die zur
              Installation gehören, lassen aufmerken. Literarische Texte aus der
              Feder von Kästner, Heine und anderen sowie Fakten mischen sich zu
              einem eindrucksvollen Ganzen. Vom Waldsterben, dem Verschwinden
              von Insekten von Wiesen und Feldern und dem Sterben ganzer Spezies
              – nicht mehr umkehrbar – ist die Rede. Eine künstlerische Position
              greift eindrucksvoll in die andere.
            </p>
            <p className="pb-16">
              Der Rohstoff Holz, den der Wald dem Menschen liefert, ist in den
              filigranen Scherenschnitten aus Druckerei-Restrollen gegenwärtig.
              Einen anderen Blick auf den Umgang mit Holz eröffnen die massiven
              Altpapierblöcke im Glasbau der Museen der Stadt Lüdenscheid.
              Bohrmaschinen stehen gleichsam für den Borkenkäfer, der die Blöcke
              (Bäume) zerstört. Das Analoge korrespondiert eng mit dem
              Digitalen, sprich dem Dokumentarfilm, in dem sich Bilder vom
              sterbenden Wald, von braunen, toten Fichten und großflächigen
              Baumfällarbeiten mit Bildern menschlichen Lebens, Autoverkehr und
              Freizeitvergnügen eindrucksvoll vermischen.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Projekt Reset the Forest"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Projekt Reset the Forest"
              />
            </div>
            <p>
              Die künstlerische Intervention verfolgt ein ganz konkretes Ziel:
              Die Rettung des Waldes. Es gilt verschiedene Interessengruppen an
              einen Tisch zu bringen. Mit dem Referenten Wilhelm Bode konnte ein
              Fortfachmann gewonnen werden, der zahlreiche Bücher, Leitfäden und
              Essay zum Thema verfasst hat.
            </p>
            <p className="pb-16">
              Am 9. September ist Wilhelm Bode zu Gast in den Museen der Stadt
              Lüdenscheid, um mit Förstern, Waldbesitzern, Naturschützern,
              Bürgermeistern, Behörden, Vertretern der Industrie und Initiativen
              über <b>„Reset the Forest“</b> zu diskutieren und Lösungsansätze
              zu entwerfen.
            </p>
            <div className="flex flex-col my-32">
              <GatsbyImage
                className="my-8 md:my-0 max-w-672 w-full"
                objectFit="contain"
                image={data.image5.childImageSharp.gatsbyImageData}
                alt="Projekt Reset the Forest"
              />
              <GatsbyImage
                className="my-8 md:my-0 md:-mt-40 lg:-mt-92 self-end w-full md:w-1/2"
                objectFit="cover"
                image={data.image6.childImageSharp.gatsbyImageData}
                alt="Projekt Reset the Forest"
              />
            </div>
            <div>
              Zu den Webseiten der Künster*innen:
              <b className="flex flex-col">
                <a
                  href="https://tomgroll.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tom Groll
                </a>
                <a
                  href="http://www.katharinaberndt.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Katharina Berndt
                </a>
                <a
                  href="https://kuno.ist/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Kuno Seltmann
                </a>
              </b>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Reset the Forest</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Ein kleines Stück Rinde zeigt die Muster, die der Borkenkäfer in
      den Baum bohrt, in natura. Überdimensional, fast kathedralartig,
      findet sich dieses Muster an den großen Glasfenstern zum
      Museumsgarten als filigrane Scherenschnittarbeit wieder. Das
      Filigrane bildet ein Gegengewicht zu den wuchtigen, tonnenschweren
      Altpapierballen, die skulptural in den Raum greifen. Ergänzend
      dazu führt ein experimenteller Dokumentarfilm aussagekräftig vor
      Augen, wie es um den Wald bestellt ist und wo die eigentliche
      Ursache des Waldsterbens liegt."
      publicURL={props.data.image5.publicURL}
      title="Reset the Forest"
    />
  );
};

export default Page;
